<template>
  <div class="layout-footer">
    <div class="container">
      <div class="header">
        <ui-logo black small></ui-logo>
        <ui-finder></ui-finder>
        <div class="contacts">
          <slot name="contacts"></slot>
        </div>
      </div>
      <!--  -->
      <div class="menu">
        <slot name="menu"></slot>
      </div>
      <!--  -->
      <div class="socials">
        <div class="links">
          <slot name="socials"></slot>
        </div>
      </div>
      <!--  -->
      <div class="by param-text">
        <slot name="by"></slot>
      </div>
      <div class="copyrights param-text">
        <slot name="copyrights"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import uiLogo from '@/components/ui/ui-logo'
import uiFinder from '@/components/ui/ui-finder'
export default {
  components: {
    uiLogo,
    uiFinder
  }
}
</script>

<style lang="scss" scoped>
.layout-footer {
  background: var(--bg);
  padding-bottom: 2em;
}
.header {
  padding: 3.75em 0;
  display: flex;
  align-items: center;
}
.contacts {
  display: flex;
  align-items: center;
  & > * {
    &:not(:last-child) {
      margin-right: 4em;
    }
  }
}
.ui-finder {
  margin-left: 2.25em;
  width: 12em;
  margin-right: auto;
}
.socials {
  text-align: center;
  position: relative;
  &:after {
    width: 3.75em;
    content: '';
    height: 1px;
    display: inline-block;
    background: currentColor;
    position: relative;
    margin: 2em auto;
  }
  .links {
    & > * {
      margin: 1em;
    }
  }
}
.by,
.copyrights {
  text-align: center;
}
.param-text {
  font-size: 0.6875em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.by {
  opacity: 0.5;
}
.copyrights {
  margin-top: 0.4em;
  & > * {
    &:not(:last-child) {
      padding-right: 1em;
      border-right: solid 1px;
      margin-right: 1em;
    }
  }
}
.menu {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 2em;
  .xs-title {
    margin-bottom: 1em;
  }
  a {
    display: block;
    opacity: 0.6;
    transition: 0.4s;
    cursor: pointer;
    margin-bottom: 0.5em;
    &:hover {
      opacity: 1;
    }
  }
}
@media screen and (max-width: 767px) {
  .menu {
    grid-template-columns: repeat(2, 1fr);
  }
  .header {
    flex-flow: wrap;
    .contacts {
      margin-top: 1em;
    }
  }
  .ui-finder {
    display: none;
    width: auto;
    margin-right: 0;
    flex-grow: 1;
    margin-left: 1em;
  }
}
</style>
