import pageLayoutDefault from '@/components/page/layout/page-layout-default.vue'
import pageLayoutMenu from '@/components/page/layout/page-layout-menu.vue'
import layoutProductCard from '@/components/layout/product/layout-product-card'
import uiButton from '@/components/ui/ui-button'
import layoutFooter from '@/components/layout/layout-footer'
import layoutHeaderButton from '@/components/layout/layout-header-button'
import layoutGlobalMenuRow from '@/components/layout/global-menu/layout-global-menu-row'
import layoutGlobalMenuColumn from '@/components/layout/global-menu/layoyt-global-menu-column'
import layoutGlobalMenu from '@/components/layout/global-menu/layout-global-menu'
import layoutContactBlockRow from '@/components/layout/layout-contact-block-row'
import uiTextureCheckbox from '@/components/ui/ui-texture-checkbox'
import uiRichColorChoicer from '@/components/ui/ui-rich-color-choicer'
import uiRichSelect from '@/components/ui/ui-rich-select'

const components = Vue => {
  Vue.component('page-layout-default', pageLayoutDefault)
  Vue.component('page-layout-menu', pageLayoutMenu)
  Vue.component('layout-product-card', layoutProductCard)
  Vue.component('ui-button', uiButton)
  Vue.component('layout-footer', layoutFooter)
  Vue.component('layout-header-button', layoutHeaderButton)
  Vue.component('layout-global-menu-row', layoutGlobalMenuRow)
  Vue.component('layout-global-menu-column', layoutGlobalMenuColumn)
  Vue.component('layout-global-menu', layoutGlobalMenu)
  Vue.component('layout-contact-block-row', layoutContactBlockRow)
  Vue.component('ui-texture-checkbox', uiTextureCheckbox)
  Vue.component('ui-rich-color-choicer', uiRichColorChoicer)
  Vue.component('ui-rich-select', uiRichSelect)
  //
  Vue.component('layout-mobile-category-list-item', () =>
    import('@/components/layout/layout-mobile-category-list-item')
  )
  //
  // Icons
  Vue.component('icon-home', () => import('@/components/icon/icon-home'))
  Vue.component('icon-chevron', () => import('@/components/icon/icon-chevron'))
  Vue.component('icon-cart', () => import('@/components/icon/icon-cart'))
  Vue.component('icon-search', () => import('@/components/icon/icon-search'))
  Vue.component('icon-catalog', () => import('@/components/icon/icon-catalog'))
  Vue.component('icon-profile', () => import('@/components/icon/icon-profile'))
  Vue.component('icon-favorite', () => import('@/components/icon/icon-favorite'))
  Vue.component('icon-close', () => import('@/components/icon/icon-close'))
}
export default components
