<template>
  <label class="ui-texture-checkbox" :class="[{ alternative }]">
    <div class="content">
      <input :name="name" :type="isRadio ? 'radio' : 'checkbox'" />
      <div class="about">
        <div class="small-text"><slot name="name"></slot></div>
        <div class="small-text">
          <b><slot name="price"></slot></b>
        </div>
      </div>
    </div>
    <div class="image">
      <img :src="$attrs.image" alt="" />
    </div>
  </label>
</template>

<script>
export default {
  props: {
    alternative: {
      type: Boolean,
      default() {
        return false
      }
    },
    name: {
      type: String,
      default() {
        return ''
      }
    },
    isRadio: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-texture-checkbox {
  display: inline-block;
  width: calc(50% - 0.5em);
  border: solid 1px rgba(0, 0, 0, 0.1);
  margin-top: 1em;
  cursor: pointer;
  transition: 0.3s;
  &.alternative {
    display: inline-flex;
    flex-direction: column-reverse;
    .content {
      padding: 1em;
    }
  }
  &:hover {
    border: solid 1px rgba(0, 0, 0, 0.8);
  }
  &:nth-child(2n + 1) {
    margin-right: 0.5em;
  }
}
.content {
  padding: 0.5em;
  display: flex;
  input {
    margin-right: 0.5em;
  }
}
.image {
  position: relative;
  height: 8.125em;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
