<template>
  <transition enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
    <div v-if="opened" class="layout-global-menu abs">
      <div class="container">
        <div class="header">
          <div class="middle-title">
            Menu
          </div>
          <ui-lang-choice class="lang"></ui-lang-choice>
          <div class="close" @click="close">
            <img src="@/assets/icons/close.svg" alt="" />
          </div>
        </div>
        <div class="columns">
          <div class="left">
            <div class="menu abs">
              <vuescroll :ops="ops">
                <slot name="menu"></slot>
              </vuescroll>
            </div>
          </div>
          <div class="right">
            <div class="contacts">
              <slot name="contacts"></slot>
            </div>
            <div class="socials">
              <slot name="socials"></slot>
            </div>
            <div class="button">
              <slot name="button"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vuescroll from 'vuescroll/dist/vuescroll-native'
import uiLangChoice from '@/components/ui/ui-lang-choice'
export default {
  components: {
    vuescroll,
    uiLangChoice
  },
  data() {
    return {
      opened: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          background: '#000',
          opacity: 0.1,
          size: '3px',
          specifyBorderRadius: '0px'
        },
        bar: {
          background: '#000',
          opacity: 1,
          size: '3px'
        }
      }
    }
  },
  created() {
    this.$bus.$on('global-menu', (status = true) => {
      this.opened = status
      document.body.style.overflow = 'hidden'
    })
  },
  methods: {
    close() {
      this.opened = false
      document.body.style.overflow = 'auto'
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-global-menu {
  --padding-top: 101px;
  position: fixed;
  z-index: 501;
  background: #fff;
  height: calc(100vh - var(--padding-top));
  top: var(--padding-top);
  overflow: hidden;
  display: flex;
}
.container {
  display: flex;
  flex-direction: column;
}
.columns {
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto 21em;
  grid-gap: 0 7em;
  & > * {
    overflow: hidden;
    position: relative;
  }
}
.menu {
  overflow: scroll;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  grid-column: span 2;
  .close {
    margin-left: 1em;
    cursor: pointer;
  }
}
.contacts {
  & > * {
    margin-bottom: 2em !important;
  }
}
.socials {
  margin-bottom: 2em;
  & > * {
    margin-right: 3em;
  }
}
.button {
  .ui-button {
    width: 15em;
  }
}
.lang {
  display: none;
}
@media screen and (max-width: 767px) {
  .lang {
    display: flex;
  }
  .layout-global-menu {
    --padding-top: 80px;
    padding-bottom: 10em;
    padding-top: 1em;
  }
  .columns {
    display: block;
    overflow: scroll;
  }
  .close {
    display: none;
  }
  .header {
    justify-content: space-between;
  }
  .menu {
    position: relative;
    left: auto;
    top: auto;
    width: auto;
    height: auto;
  }
}
</style>
