<template>
  <div class="layout-product-promos">
    <div v-if="sale" class="sale xxs-title">{{ sale }}</div>
    <div v-if="delivery" class="delivery">
      <img src="@/assets/icons/delivery.svg" alt="" />
      <div class="xs-title">free delivery: {{ delivery }}</div>
    </div>
    <div v-if="pickup" class="pickup">
      <img src="@/assets/icons/self.svg" alt="" />
      <div class="xs-title">pickup: {{ pickup }}</div>
    </div>
    <div class="stars">
      <div v-for="star in 5" :key="star" :class="[{ active: Number(stars) >= star }]" class="star">
        <img src="@/assets/icons/star.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stars: {
      type: String,
      default() {
        return ''
      }
    },
    sale: {
      type: String,
      default() {
        return ''
      }
    },
    delivery: {
      type: String,
      default() {
        return '0'
      }
    },
    pickup: {
      type: String,
      default() {
        return '0'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-product-promos {
  display: flex;
  align-items: center;
  margin: 2em 0;
  margin-left: 9em;
  &.left {
    margin-left: 0;
  }
  & > * {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 2em;
    }
    img {
      margin-right: 0.3em;
    }
  }
}
.sale {
  background: var(--red);
  color: #fff;
  padding: 0.4em 0.5em;
}
.star {
  opacity: 0.2;
  &.active {
    opacity: 1;
  }
}
@media screen and (max-width: 767px) {
  .layout-product-promos {
    margin-left: 0;
    flex-flow: wrap;
    & > * {
      margin: 0.2em 0;
      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
}
</style>
