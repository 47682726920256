<template>
  <transition enter-to-class="animated fadeInRight" leave-active-class="animated fadeOutRight">
    <div v-if="value" class="layout-filter-window abs">
      <div class="abs" @click="close"></div>
      <div class="window">
        <div class="header">
          <div class="small-title">{{ title }}</div>
          <div class="close" @click="close">
            <img src="@/assets/icons/close.svg" alt="" />
          </div>
        </div>
        <div class="content">
          <slot></slot>
          <!--  -->
          <ui-button fill @click="close">Apply</ui-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-filter-window {
  position: fixed;
  z-index: 999;
}
.window {
  background: #ffffff;
  box-shadow: -170px 4px 124px rgba(0, 0, 0, 0.08);
  width: 30em;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 2.5em;
  display: flex;
  flex-direction: column;
  & > .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    & > .ui-button {
      margin-top: auto;
      align-self: flex-start;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .close {
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: rotate(90deg);
    }
  }
}
@media screen and (max-width: 767px) {
  .window {
    width: 100%;
  }
}
</style>
