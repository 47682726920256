<template>
  <div :class="[{ fullWidth }]" class="layoyt-global-menu-column">
    <div class="xs-title"><slot name="title"></slot></div>
    <div v-if="$slots.default" class="links">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layoyt-global-menu-column {
  &.fullWidth {
    grid-column: span 4;
    .links {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.links {
  margin-top: 0.5em;
  & > * {
    display: block;
    font-size: 0.9375em;
    padding: 0.3em 0;
    opacity: 0.7;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}
@media screen and (max-width: 767px) {
  .layoyt-global-menu-column {
    &.fullWidth {
      .links {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
</style>
