<template>
  <div v-if="!noMenu" class="page-layout-menu">
    <div class="container">
      <div class="menu">
        <div class="menu-button" @click="$bus.$emit('global-menu')">
          <img src="@/assets/icons/menu-button.svg" alt="" />
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    noMenu() {
      return this.$parent.noMenu
    }
  },
  mounted() {
    setTimeout(() => {
      this.position()
    }, 1000)
    window.onresize = () => {
      this.position()
    }
  },
  methods: {
    position() {
      const submenus = this.$el.querySelectorAll('.submenu')
      for (const submenu of submenus) {
        submenu.style.left = 'auto'
      }
      for (const submenu of submenus) {
        console.dir(submenu)
        const coords = submenu.getBoundingClientRect()
        submenu.style.left = `${(Math.round(coords.x) - 20 + 4) * -1}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-layout-menu {
  margin-bottom: 1em;
  position: relative;
}
.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    position: relative;
    padding: 1em;
    &:hover {
      background: #000;
      color: #fff;
      .submenu {
        opacity: 1;
        visibility: visible;
      }
    }
    & > a {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      transition: 0.4s;
      position: relative;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.submenu {
  position: absolute;
  top: 100%;
  background: #000;
  color: #fff;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 74px 64px rgba(0, 0, 0, 0.25);
  & > .container {
    padding: 2.5em 0;
    display: flex;
    & > * {
      flex-grow: 1;
      // column
      & > :nth-child(1) {
        font-size: 0.9375em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-bottom: 1.5em;
      }
      .items {
        &.columns {
          display: grid;
          grid-template-rows: repeat(10, 1fr);
          grid-auto-flow: column;
        }
        & > * {
          display: block;
          font-size: 0.9375em;
          margin-bottom: 0.5em;
          opacity: 0.5;
          transition: 0.5s;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
.menu-button {
  cursor: pointer;
  position: relative;
  top: 0.15em;
  transition: 0.5s;
  &:hover {
    transform: scale(1.02);
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .page-layout-menu {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .page-layout-menu {
    display: none;
  }
}
</style>
