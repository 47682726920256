<template>
  <div class="ui-rich-color-choicer">
    <div class="visible">
      <div class="mini-colors">
        <div
          v-for="(color, index) in colors.splice(0, 4)"
          :key="index"
          :class="[{ active: color.src === current }]"
          class="color"
          @click="current = color.src"
        >
          <img :src="color.src" alt="" />
        </div>
      </div>
      <img src="/images/more-button.svg" alt="" @click="opened = true" />
    </div>
    <layout-filter-window v-model="opened" title="Select material">
      <div class="current-color">
        <div class="big-color color">
          <img :src="current" alt="" />
        </div>
        <div class="current-content">
          <div class="xxs-title">Current</div>
          <div class="xs-title">{{ foundColor.name }}</div>
          <div class="caption">Features: {{ foundColor.tags.join(', ') }}</div>
        </div>
      </div>
      <div class="filters-wrapper">
        <div class="xxs-title">
          filter by
        </div>
        <div class="filters">
          <select name="">
            <option value="Color">Color</option>
            <option v-for="(prop, index) in allProperties" :key="index" :value="prop">{{ prop }}</option>
          </select>
          <select name="">
            <option value="Type & Features">Type & Features</option>
            <option v-for="(tag, index) in allTags" :key="index" :value="tag">{{ tag }}</option>
          </select>
        </div>
      </div>
      <!--  -->
      <div class="colors-wrapper">
        <div class="xxs-title">Make your choice</div>
        <div class="colors-scroll-area">
          <vuescroll :ops="ops">
            <div class="colors">
              <div v-for="(color, index) in colors" :key="index" class="color" @click="current = color.src">
                <img :src="color.src" alt="" />
              </div>
            </div>
          </vuescroll>
        </div>
      </div>
    </layout-filter-window>
  </div>
</template>

<script>
import vuescroll from 'vuescroll/dist/vuescroll-native'
import layoutFilterWindow from '@/components/layout/layout-filter-window'
export default {
  components: {
    layoutFilterWindow,
    vuescroll
  },
  props: {
    colors: {
      type: Array,
      default() {
        return []
      }
    },
    currentColor: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      current: this.currentColor,
      opened: false,
      filterProperty: '',
      filterTag: '',
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          background: '#000',
          opacity: 0.1,
          size: '3px',
          specifyBorderRadius: '0px'
        },
        bar: {
          background: '#000',
          opacity: 1,
          size: '3px'
        }
      }
    }
  },
  computed: {
    foundColor() {
      return this.colors.find(c => c.src === this.current)
    },
    allProperties() {
      const array = []
      for (const color of this.colors) {
        array.push(...color.props)
      }
      return [...new Set(array)]
    },
    allTags() {
      const array = []
      for (const color of this.colors) {
        array.push(...color.tags)
      }
      return [...new Set(array)]
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-rich-color-choicer {
  margin-top: 0.5em;
  margin-bottom: 2em;
}
.visible {
  display: flex;
  align-items: center;
  & > img {
    margin-left: 1em;
    cursor: pointer;
  }
}
.mini-colors {
  display: flex;
}
.color {
  width: 2em;
  height: 2em;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-right: 0.5em;
  cursor: pointer;
  transition: 0.3s;
  &.active {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
  }
  &:hover {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    border-radius: inherit;
  }
}
.big-color {
  width: 6.8em;
  height: 6.8em;
  border: 5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.92);
}
.current-color {
  display: flex;
  align-items: center;
  margin-top: 2em;
  .big-color {
    margin-right: 1em;
  }
}
.current-content {
  width: 12.5em;
  max-width: 100%;
  .caption {
    margin-top: 1em;
    opacity: 0.4;
  }
}
.filters-wrapper {
  margin-top: 2em;
}
.filters {
  margin-top: 0.5em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5em;
}
.colors-wrapper {
  margin-top: 3em;
}
.colors-scroll-area {
  margin-top: 1em;
  height: 21em;
  .colors {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1em;
  }
  .color {
    width: 4.5em;
    height: 4.5em;
  }
}
</style>
