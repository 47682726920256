<template>
  <div class="ui-breadcrumbs">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.ui-breadcrumbs {
  display: flex;
  & > * {
    font-size: 0.75em;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-right: 0.5em;
      &:after {
        content: '/';
        margin-left: 0.5em;
      }
    }
  }
}
</style>
