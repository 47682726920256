<template>
  <div class="layout-product-data">
    <div class="container">
      <div class="left">
        <ui-breadcrumbs v-if="$slots.breadcrumbs">
          <slot name="breadcrumbs"></slot>
        </ui-breadcrumbs>
        <layout-product-promos
          :class="[{ left: !$slots.breadcrumbs }]"
          :stars="stars"
          :sale="sale"
          :delivery="delivery"
          :pickup="pickup"
        ></layout-product-promos>
        <layout-product-gallery>
          <slot slot="video" name="video"></slot>
          <slot name="gallery"></slot>
        </layout-product-gallery>
      </div>
      <div class="right">
        <div class="middle-title">
          <slot name="title"></slot>
          <div class="like">
            <img v-if="isFavorite === 'false'" src="@/assets/icons/heart.svg" alt="" />
            <img v-else src="@/assets/icons/heart-fill.svg" alt="" />
          </div>
        </div>
        <div class="code-ask">
          <div class="code small-text">Code: <slot name="code"></slot></div>
          <div class="ask">
            <img src="@/assets/icons/chat.svg" alt="" />
            <div class="subtitle">Ask a question</div>
          </div>
        </div>
        <div class="params">
          <slot name="params"></slot>
        </div>
        <div class="price-block">
          <div class="prices">
            <div class="price"><slot name="price"></slot></div>
            <div v-if="$slots['old-price']" class="old-price"><slot name="old-price"></slot></div>
          </div>
          <div class="notify">
            <div class="xxs-title">notify me</div>
            <img src="@/assets/icons/notify.svg" alt="" />
          </div>
        </div>
        <div class="buttons">
          <slot name="buttons"></slot>
        </div>
        <div v-if="$slots.complete" class="complete">
          <div class="xs-title">
            complete the set
          </div>
          <div class="complete-items">
            <slot name="complete"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uiBreadcrumbs from '@/components/ui/ui-breadcrumbs'
import layoutProductGallery from '@/components/layout/product/layout-product-gallery'
import layoutProductPromos from '@/components/layout/product/layout-product-promos'
export default {
  components: {
    uiBreadcrumbs,
    layoutProductGallery,
    layoutProductPromos
  },
  props: {
    stars: {
      type: String,
      default() {
        return ''
      }
    },
    sale: {
      type: String,
      default() {
        return ''
      }
    },
    delivery: {
      type: String,
      default() {
        return '0'
      }
    },
    pickup: {
      type: String,
      default() {
        return '0'
      }
    },
    isFavorite: {
      type: String,
      default() {
        return 'false'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-product-data {
  padding-bottom: 3em;
}
.container {
  display: grid;
  grid-template-columns: auto 20em;
  grid-gap: 4.5em;
}
.middle-title {
  position: relative;
  padding-right: 0.5em;
  .like {
    position: absolute;
    right: 0;
    top: -0.4em;
  }
}
.right {
  padding-top: 1.8em;
}
.code {
  background: var(--bg);
  display: inline-flex;
  padding: 0.2em 0.3em;
  margin-right: 1em;
}
.ask {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 0.5em;
  }
}
.code-ask {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}
.params {
  margin-top: 3em;
}
.prices {
  display: flex;
  align-items: center;
  .price {
    font-size: 1.5em;
    font-weight: 700;
  }
  .old-price {
    font-size: 0.875em;
    opacity: 0.4;
    text-decoration: line-through;
    font-weight: 600;
    margin-left: 1em;
  }
}
.notify {
  display: flex;
  align-items: center;
  img {
    margin-left: 0.5em;
  }
}
.price-block {
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buttons {
  .ui-button {
    width: 100%;
    margin: 0.2em 0;
  }
}
.complete {
  margin-top: 1em;
}

.complete-items {
  margin-top: 0.5em;
  & > * {
    display: grid;
    padding: 0.5em;
    border: solid 1px rgba(0, 0, 0, 0.1);
    grid-template-columns: min-content auto 9em;
    grid-gap: 1em;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}
@media screen and (max-width: 767px) {
  .container {
    display: block;
  }
}
</style>
