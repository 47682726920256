<template>
  <div :class="[{ reverse }]" class="layout-header-button" @click="$emit('click')">
    <div class="xxs-title"><slot></slot></div>
    <img :src="require(`@/assets/icons/${icon}`)" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    reverse: {
      type: Boolean,
      default() {
        return false
      }
    },
    icon: {
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-header-button {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  &.reverse {
    flex-direction: row-reverse;
    img {
      margin-left: 0;
      margin-right: 0.5em;
    }
  }
  img {
    margin-left: 0.5em;
    width: 1em;
    height: 1em;
    max-width: none;
    max-height: none;
  }
}
</style>
