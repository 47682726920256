<template>
  <div class="page-layout-default" :class="[{ flex }]">
    <layout-header v-if="!isMobile">
      <slot slot="buttons" name="header-buttons"></slot>
      <slot slot="left" name="left"></slot>
      <slot slot="menu" name="menu"></slot>
    </layout-header>
    <layout-mobile-header v-else></layout-mobile-header>
    <slot />
    <layout-mobile-navbar v-if="isMobile">
      <slot name="navbar"></slot>
    </layout-mobile-navbar>
    <!--  -->
    <layout-popup></layout-popup>
    <portal-target name="popup"></portal-target>
    <layout-city-choice-popup :city="city" :dadata-key="dadataKEY" @update="city = $event"></layout-city-choice-popup>
    <transition enter-active-class="animated slideInRight" leave-active-class="animated slideOutRight">
      <layout-global-mobile-menu v-if="globalMenuOpened" :menu="$attrs['mobile-menu']">
        <slot slot="mobile-favorites" name="mobile-favorites"></slot>
        <slot slot="category" name="mobile-category"></slot>
      </layout-global-mobile-menu>
    </transition>
    <slot name="global-menu"></slot>
  </div>
</template>

<script>
import layoutHeader from '@/components/layout/layout-header'
import layoutPopup from '@/components/layout/layout-popup'
import layoutCityChoicePopup from '@/components/layout/layout-city-choice-popup'
export default {
  components: {
    layoutCityChoicePopup,
    layoutHeader,
    layoutPopup,
    layoutMobileHeader: () => import('@/components/layout/layout-mobile-header'),
    layoutMobileNavbar: () => import('@/components/layout/layout-mobile-navbar'),
    layoutGlobalMobileMenu: () => import('@/components/layout/layout-global-mobile-menu')
  },
  props: {
    noMenu: {
      type: Boolean,
      default() {
        return false
      }
    },
    flex: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      city: null,
      dadataKEY: 'b0ee60baf097afc5d01864c91233b0ef69a0d56e'
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile
    },
    globalMenuOpened() {
      return this.$store.state.globalMenuOpened
    }
  },
  created() {
    if (window.innerWidth < 768) {
      this.$store.state.isMobile = true
    } else {
      this.$store.state.isMobile = false
    }
  },
  mounted() {
    this.city = this.$cookie.get('vs-city')
    this.fetchCity()
  },
  methods: {
    async fetchCity() {
      if (!this.city) {
        const { location } = (
          await this.$axios({
            url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address',
            method: 'get',
            headers: {
              Authorization: 'Token ' + this.dadataKEY
            }
          })
        ).data
        if (location) {
          this.city = location.data.city
          this.$cookie.set('vs-city', this.city)
        } else {
          console.log('Запросим город')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-layout-default {
  position: relative;
  padding-top: calc(var(--header-height) + 3em);
  overflow: hidden;
  &.flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}
.portal-place {
  position: fixed;
  z-index: 501;
  background: #ccc;
}
@media screen and (max-width: 767px) {
  .page-layout-default {
    padding-top: calc(var(--header-height) + 0em);
    padding-bottom: var(--navbar-height);
  }
}
</style>
