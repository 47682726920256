<template>
  <div class="layout-header">
    <div class="header-wrapper">
      <div class="container">
        <div class="left align-left">
          <ui-finder></ui-finder>
          <slot name="left"></slot>
          <ui-lang-choice></ui-lang-choice>
        </div>
        <div class="center align-center">
          <ui-logo></ui-logo>
        </div>
        <div class="right align-right">
          <slot name="buttons"></slot>
        </div>
      </div>
      <div class="more" @click="toggle">
        <img v-if="!menuOpened" src="@/assets/icons/more.svg" alt="" />
        <img v-else src="@/assets/icons/close.svg" alt="" />
      </div>
    </div>
    <div class="menu-wrapper">
      <slot name="menu"></slot>
    </div>
  </div>
</template>

<script>
import uiLogo from '@/components/ui/ui-logo'
import uiFinder from '@/components/ui/ui-finder'
import uiLangChoice from '@/components/ui/ui-lang-choice'
export default {
  components: {
    uiLogo,
    uiFinder,
    uiLangChoice
  },
  data() {
    return {
      menuOpened: false
    }
  },

  methods: {
    toggle() {
      this.menuOpened = !this.menuOpened
      this.$bus.$emit('global-menu', this.menuOpened)
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-header {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 500;
  background: #fff;
}
.header-wrapper {
  padding: 2em 0;
}
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}
.more {
  display: none;
}
.left {
  display: flex;
  & > * {
    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}
.right {
  & > * {
    &:not(:first-child) {
      margin-left: 1.5em;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .left {
    display: none;
  }
  .container {
    display: flex;
    align-items: center;
  }
  .center {
    margin-right: auto;
  }
}
@media screen and (max-width: 767px) {
  .layout-header {
    padding: 1em 0;
  }
  .container {
    display: block;
  }
  .header-wrapper {
    padding: 0;
  }
  .left {
    position: absolute;
    top: 3px;
    & > * {
      &:not(:first-child) {
        display: none;
      }
    }
    // display: none;
  }
  .right {
    display: grid;
    grid-template-columns: repeat(4, auto);
    text-align: left;
    justify-items: flex-start;
    gap: 0.5em;
    padding-top: 1em;
  }
  .center {
    margin-right: auto;
  }
  .ui-logo {
    font-size: 1.5em;
  }
  .button {
    flex-direction: row-reverse;
    img {
      margin-right: 0.4em;
    }
    &:not(:last-child) {
      margin-right: 0;
    }
  }
  .more {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
    display: block;
    width: 3em;
    height: 3em;
    opacity: 0.6;
    z-index: 10;
    padding: 1em;
  }
}
</style>
