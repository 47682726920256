<template>
  <div class="ui-rich-select">
    <div class="placeholder" @click="opened = true">
      {{ current }}
      <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 3.5L0.25 0.468911L0.25 6.53109L4 3.5Z" fill="black" />
      </svg>
    </div>
    <layout-filter-window v-model="opened" :title="title">
      <div class="cards">
        <slot></slot>
      </div>
    </layout-filter-window>
  </div>
</template>

<script>
import layoutFilterWindow from '@/components/layout/layout-filter-window'
export default {
  components: {
    layoutFilterWindow
  },
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    current: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      opened: false,
      options: []
    }
  },
  mounted() {
    console.log(this.$slots.default)
    this.options = this.$slots.default.filter(t => t.tag)
  }
}
</script>

<style lang="scss" scoped>
.ui-rich-select {
  margin-bottom: 2em;
}
.placeholder {
  display: block;
  resize: none;
  width: 100%;
  font-size: 0.9375em;
  font-family: inherit;
  color: inherit;
  display: flex;
  align-items: center;
  position: relative;
  height: 3.33em;
  padding: 0 0.8em;
  border: solid 1px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  cursor: pointer;
  margin-top: 0.5em;
}
</style>

<style lang="scss">
.ui-rich-select {
  .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    & > * {
      width: auto !important;
      margin-top: 1em;
    }
  }
}
</style>
