<template>
  <div class="ui-finder">
    <layout-header-button reverse icon="search.svg" @click="open">
      Search
    </layout-header-button>
    <!--  -->
    <transition enter-active-class="animated zoomIn" leave-active-class="animated zoomOut">
      <div v-if="opened" class="finder-popup abs">
        <img src="@/assets/icons/close.svg" class="close" alt="" @click="close" />
        <input autofocus class="finder" placeholder="type to search" type="text" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opened: false
    }
  },
  methods: {
    open() {
      this.opened = true
      document.body.style.overflow = 'hidden'
    },
    close() {
      this.opened = false
      document.body.style.overflow = 'auto'
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-finder {
  position: relative;
}
input {
  font-family: inherit;
  font-size: 0.825em !important;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  padding: 1em 0 !important;
  background: none;
  border: none !important;
  border-bottom: solid 1px !important;
  height: auto !important;
  border-radius: 0;
  width: 40em !important;
  max-width: 100%;
}
.finder-popup {
  background: rgba(255, 255, 255, 1);
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.close {
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  right: 2em;
  top: 2em;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    transform: rotate(90deg);
  }
}
</style>
