<template>
  <div class="section-categories">
    <div class="container">
      <div class="great-title"><slot name="title"></slot></div>
      <div class="tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          :class="[{ active: index === current }]"
          class="tab xs-title"
          @click="current = index"
        >
          {{ tab.name }}
        </div>
      </div>
      <div v-for="(tab, index) in tabs" v-show="index === current" :key="index" class="tab-content" :class="[tab.id]">
        <slot :name="tab.id"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      tabs: [
        {
          name: 'По категориям',
          id: 'categories'
        },
        {
          name: 'По брендам',
          id: 'brands'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.section-categories {
  padding-bottom: 5em;
}
.categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}
.great-title {
  margin-bottom: 0.7em;
}
.brands {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1em;
  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 10em;
    position: relative;
    background: var(--bg);
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
    img {
      max-width: 10em;
      max-height: none;
      mix-blend-mode: darken;
      transition: 0.5s;
    }
  }
}
.tabs {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin-bottom: 1.5em;
  .tab {
    margin-right: 1.5em;
    margin-bottom: 0.5em;
    transition: 0.5s;
    opacity: 0.3;
    cursor: pointer;
    &.active {
      opacity: 1;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .categories {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .categories {
    display: block;
    & > * {
      margin-bottom: 1em;
      display: block;
    }
  }
  .brands {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
