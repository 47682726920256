<template>
  <div class="layout-global-menu-row">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.layout-global-menu-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 4em;
}
@media screen and (max-width: 767px) {
  .layout-global-menu-row {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
