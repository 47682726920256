<template>
  <div class="layout-product-card">
    <div class="image">
      <div class="image-wrapper abs" @click="open">
        <slot name="image"></slot>
      </div>
      <div class="labels abs">
        <slot name="label"></slot>
      </div>
      <div class="zoom abs">
        <img src="@/assets/icons/zoom.svg" alt="" @click="opened = true" />
      </div>
    </div>
    <div class="content">
      <div class="header">
        <slot name="name"></slot>
        <img src="@/assets/icons/compare.svg" alt="" />
        <img v-if="!isFavorite" src="@/assets/icons/heart.svg" alt="" />
        <img v-else src="@/assets/icons/heart-fill.svg" alt="" />
      </div>
      <!--  -->
      <div v-if="$slots.colors" class="colors">
        <slot name="colors"></slot>
      </div>
      <!--  -->
      <div class="options">
        <div v-if="hardness && hardnessPercent" class="option">
          <div class="hardness-progress">
            <svg
              :style="{ left: `${hardnessPercent}%` }"
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 0L7.4641 6H0.535898L4 0Z" fill="black" />
            </svg>
          </div>
          <div class="param-text">{{ hardness }}</div>
        </div>
        <div v-if="sizes" class="option">
          <div class="param-text">sizes</div>
          <div class="sizes">
            <a v-for="(size, index) in sizesArray" :key="index" :href="`/product?size=${size}`" class="size">
              {{ size }}
            </a>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="chars">
        <slot name="char"></slot>
        <div class="char-more">
          <icon-arrow-dropdown></icon-arrow-dropdown>
        </div>
        <div class="hidden-chars">
          <slot name="hidden-char"></slot>
        </div>
      </div>
      <!--  -->
      <div class="bottom">
        <div class="link subtitle"><slot name="link"></slot></div>
        <div class="price subtitle" @click="open">{{ Number(price).toLocaleString() }} ₽</div>
        <div class="price subtitle old" @click="open">{{ Number(oldPrice).toLocaleString() }} ₽</div>
      </div>
      <!--  -->
      <portal v-if="opened" to="popup">
        <layout-product-card-preview v-model="opened"></layout-product-card-preview>
      </portal>
    </div>
  </div>
</template>

<script>
import layoutProductCardPreview from '@/components/layout/product/layout-product-card-preview'
export default {
  components: {
    layoutProductCardPreview,
    iconArrowDropdown: () => import('@/components/icon/icon-arrow-dropdown')
  },
  props: {
    price: {
      type: String,
      default() {
        return null
      }
    },
    oldPrice: {
      type: String,
      default() {
        return null
      }
    },
    hardness: {
      type: String,
      default() {
        return null
      }
    },
    hardnessPercent: {
      type: String,
      default() {
        return null
      }
    },
    sizes: {
      type: String,
      default() {
        return null
      }
    },
    params: {
      type: String,
      default() {
        return null
      }
    },
    isFavorite: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      opened: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          background: '#000',
          opacity: 0.1,
          size: '3px',
          specifyBorderRadius: '0px'
        },
        bar: {
          background: '#000',
          opacity: 1,
          size: '3px'
        }
      }
    }
  },
  computed: {
    productParams() {
      return this.$store.state.productParams
    },
    sizesArray() {
      return this.sizes.split(',')
    },
    paramsArray() {
      return this.params.split(',').map(p => {
        const found = this.productParams.find(pp => pp.name.toLowerCase() === p.toLowerCase())
        return found || null
      })
    }
  },
  methods: {
    open() {
      window.location = '/product'
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-product-card {
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    width: calc(100% + 2em);
    height: calc(100% + 2em);
    left: -1em;
    top: -1em;
    display: block;
    position: absolute;
    background: #fff;
    transform: scale(0.95);
    opacity: 0;
    transition: 0.5s;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    z-index: -1;
  }
  &:hover {
    z-index: 2;
    &::before {
      opacity: 1;
      transform: none;
    }
  }
}
.image {
  padding-bottom: 100%;
  background: var(--bg);
  position: relative;
  cursor: pointer;
  margin-bottom: 1.25em;
  &:hover {
    .zoom {
      opacity: 1;
      visibility: visible;
    }
  }
  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: contain;
    mix-blend-mode: darken;
  }
}
.zoom {
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  pointer-events: none;
  & > img {
    pointer-events: all;
  }
}
.header {
  display: flex;
  img {
    cursor: pointer;
  }
  & > * {
    &:not(:last-child) {
      margin-right: 0.5em;
    }
    &:first-child {
      margin-right: auto;
      width: 12em;
    }
  }
}
.hardness-progress {
  width: 100%;
  background: linear-gradient(90deg, #a7bc96 0%, #c28b98 100%);
  border-radius: 20px;
  height: 7px;
  position: relative;
  margin-bottom: 8px;
  svg {
    position: absolute;
    top: 100%;
  }
}
.options {
  margin-top: 1em;
  display: grid;
  grid-template-columns: 1.2fr 1.2fr;
  gap: 1em;
}
.param-text {
  font-size: 0.6875em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.sizes {
  display: flex;
  flex-flow: wrap;
}
.size {
  font-size: 7px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 500;
  display: inline-flex;
  border: solid 1px;
  padding: 2px 3px;
  border-radius: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  position: relative;
  transition: 0.3s;
  &:hover {
    background: var(--color);
    color: #fff;
  }
}
.params {
  grid-row: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.bottom {
  display: flex;
  align-items: center;
  margin-top: 1.6em;
  .link {
    font-weight: 600;
    text-decoration: underline;
    margin-right: auto;
  }
}
.price {
  font-weight: 600;
  &.old {
    opacity: 0.4;
    margin-left: 0.5em;
    text-decoration: line-through;
  }
}
.param {
  position: relative;
  &:hover {
    .param-tooltip {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
}
.param-tooltip {
  position: absolute;
  left: -1em;
  top: calc(100%);
  width: 12.5em;
  background: #ffffff;
  box-shadow: 0px 14px 74px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 1em);
  transition: 0.3s;
  padding: 1em;
  &::before {
    content: '';
    position: absolute;
    left: 1em;
    top: -10px;
    border: 5px solid transparent;
    border-bottom: 5px solid #fff;
  }
  .param-desc {
    margin-top: 1em;
    font-size: 0.75em;
  }
}
.hidden-chars {
  position: absolute;
  bottom: calc(100% + 3px);
  right: 0;
  background: #fff;
  box-shadow: 0px 4px 68px rgba(61, 74, 108, 0.32);
  border-radius: 20px 20px 0px 20px;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: translate(0, 10px);
  .char-more:hover + & {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 12px;
    text-transform: uppercase;
    font-size: 12px;
    div {
      width: 40px;
      height: 40px;
      background: var(--bg);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      flex-shrink: 0;
      img {
        height: 22px;
        width: auto;
      }
    }
    b {
      display: block;
    }
    span {
      padding: 5px 0;
      flex-grow: 1;
    }
    &:not(:last-child) {
      margin-bottom: 3px;
      span {
        border-bottom: solid 1px var(--light);
      }
    }
  }
}

.chars {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  position: relative;
  .char-more {
    background: var(--bg);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
    svg {
      transform: rotate(180deg);
    }
  }
  div:not(.char-more) {
    text-transform: uppercase;
    font-size: 12px;
    b {
      display: block;
    }
  }
}
@media screen and (max-width: 767px) {
  .options {
    display: block;
    & > * {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }
  .header {
    align-items: flex-start;
    & > * {
      &:first-child {
        margin-right: auto;
        width: 10em;
        position: relative;
        top: -0.2em;
        line-height: 100%;
      }
    }
  }
  .bottom {
    flex-flow: wrap;
    position: relative;
    .price {
      &.old {
        position: absolute;
        right: 0;
        bottom: 100%;
        font-size: 0.75em;
      }
    }
  }
  .sizes {
    margin-top: 0.5em;
    flex-flow: unset;
    overflow: scroll;
    margin-left: -15px;
    padding: 0 15px;
    // margin-left: -15px;
    // padding-left: 15px;
    // padding-right: 0px;
  }
  .size {
    font-size: 1em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 5px;
    flex-shrink: 0;
  }
  .params {
    display: flex;
    margin-left: -15px;
    padding: 0 15px;
    overflow: scroll;
    .param {
      font-size: 1.5em;
      flex-shrink: 0;
      margin-right: 2em;
    }
  }
}

.labels {
  pointer-events: none;
  & > * {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    background: var(--red);
    color: #fff;
    padding: 2px 4px;
    margin-right: 2px;
    margin-bottom: 2px;
  }
}
@media screen and (max-width: 767px) {
  .chars {
    flex-flow: wrap;
    & > * {
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }
}
</style>

<style lang="scss">
.layout-product-card {
  .colors {
    margin-top: 0.3em;
    & > * {
      display: flex;
      flex-flow: wrap;
      & > * {
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background: var(--color);
        margin: 0.2em;
      }
    }
  }
}
</style>
