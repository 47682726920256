<template>
  <a href="/" :class="[{ black }, { small }]" class="ui-logo">
    Время сна
  </a>
</template>

<script>
export default {
  props: {
    black: {
      type: Boolean,
      default() {
        return false
      }
    },
    small: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-logo {
  display: inline-flex;
  background: #4c6480;
  color: #fff;
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: 500;
  font-size: 1.875em;
  letter-spacing: 0.3em;
  padding: 0 0.3em;
  text-transform: uppercase;
  white-space: nowrap;
  flex-shrink: 0;
  &.black {
    background: #000;
  }
  &.small {
    font-size: 1.375em;
  }
}
</style>
