<template>
  <div class="layout-product-gallery">
    <div class="hidden abs">
      <slot></slot>
    </div>
    <div class="gallery">
      <div class="minies">
        <div
          v-for="(mini, index) in items"
          :key="index"
          :class="[{ active: index === current }]"
          class="mini"
          @click="current = index"
        >
          <img class="abs" :src="mini.src" alt="" />
        </div>
        <div class="mini active video-consult" @click="videoPopupOpened = true">
          <div class="icon"><img src="@/assets/icons/webcam.svg" alt="" /></div>
          <div class="name">
            <b>order a video</b><br />
            consultation
          </div>
        </div>
      </div>
      <div class="photo">
        <div
          :style="{
            transform: `translate3d(calc(100% * ${current} * -1),0,0)`
          }"
          class="previews"
        >
          <div v-for="(item, index) in items" :key="index" class="preview">
            <img class="abs" :src="item.src" alt="" />
          </div>
        </div>
      </div>
    </div>
    <portal v-if="videoPopupOpened" to="popup">
      <div class="video-popup abs">
        <div class="overlay abs" @click="videoPopupOpened = false"></div>
        <div class="close" @click="videoPopupOpened = false"><img src="@/assets/icons/close-white.svg" alt="" /></div>
        <div class="video">
          <slot name="video"></slot>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoPopupOpened: false,
      current: 0,
      items: []
    }
  },
  mounted() {
    this.init()
    this.initSwiper()
  },
  methods: {
    init() {
      const items = this.$el.querySelectorAll('.hidden > *')
      this.items = items
    },
    initSwiper() {
      const Hammer = require('hammerjs')
      const stage = this.$el
      const hammertime = new Hammer(stage)
      hammertime.on('swipeleft', ev => {
        this.next()
      })
      hammertime.on('swiperight', ev => {
        this.prev()
      })
    },
    next() {
      if (this.current !== this.items.length - 1) {
        this.current++
      } else {
        this.current = 0
      }
    },
    prev() {
      if (this.current !== 0) {
        this.current--
      } else {
        this.current = this.items.length - 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-product-gallery {
  position: relative;
}
.hidden {
  display: none;
}
.gallery {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 2em;
}
.mini {
  --height: 7em;
  position: relative;
  width: var(--height);
  height: var(--height);
  transition: 0.5s;
  cursor: pointer;
  opacity: 0.4;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  img {
    object-fit: contain;
  }
  &.active {
    opacity: 1;
  }
}
.photo {
  --height: 35em;
  overflow: hidden;
  height: var(--height);
}
.previews {
  transition: 1s;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
}
.preview {
  position: relative;
  height: var(--height);
  img {
    object-fit: contain;
  }
}
.video-consult {
  .name {
    margin-top: 0.5em;
    text-transform: uppercase;
    font-size: 0.875em;
  }
}
.video-popup {
  position: fixed;
  z-index: 501;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay {
  background: rgba(0, 0, 0, 0.8);
}
.close {
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 1;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: scale(1.02);
  }
}
@media screen and (max-width: 767px) {
  .gallery {
    display: flex;
    flex-direction: column-reverse;
  }
  .photo {
    --height: 18em;
  }
  .minies {
    display: flex;
    flex-flow: wrap;
  }
  .mini {
    --height: 5em;
  }
  .video-consult {
    margin-left: auto;
    width: auto;
    .name {
      white-space: nowrap;
    }
  }
}
</style>

<style lang="scss">
.video-popup {
  .video {
    position: relative;
    background: #000;
    width: 80vw;
    height: 60vh;
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
</style>
