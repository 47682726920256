<template>
  <div v-if="value" class="layout-product-card-preview abs">
    <div class="overlay abs" @click="close"></div>
    <div class="window">
      <div class="container">
        <div class="buttons">
          <div class="open" @click="open"><img src="@/assets/icons/out.svg" alt="" /></div>
          <div class="close" @click="close"><img src="@/assets/icons/close.svg" alt="" /></div>
        </div>
        <layout-product-data is-favorite="true" sale="Sale -10%" delivery="tomorrow" pickup="today" stars="4">
          <template slot="gallery">
            <img src="/images/product-1.jpg" alt="" />
            <img src="/images/product-2.jpg" alt="" />
            <img src="/images/product-3.jpg" alt="" />
          </template>
          <h1 slot="title">Evita Promo</h1>
          <template slot="code">
            00-00005704
          </template>
          <template slot="params">
            <!--  -->
            <label class="xs-title"
              >Select size
              <small>Did not find the right size?</small>
            </label>
            <select>
              <option value="190 x 80 sm (3 items)">190 x 80 sm (3 items)</option>
              <option value="190 x 80 sm (3 items)">190 x 80 sm (3 items)</option>
              <option value="190 x 80 sm (3 items)">190 x 80 sm (3 items)</option>
              <option value="190 x 80 sm (3 items)">190 x 80 sm (3 items)</option>
            </select>
            <!--  -->
            <label class="xs-title">Select material</label>
            <select>
              <option value="Select material">Select material</option>
              <option value="Select material">Select material</option>
              <option value="Select material">Select material</option>
              <option value="Select material">Select material</option>
            </select>
          </template>
          <template slot="price">
            $1099
          </template>
          <template slot="old-price">
            $1200
          </template>
          <template slot="buttons">
            <ui-button fill>Add to Cart</ui-button>
            <ui-button>by in credit</ui-button>
          </template>
        </layout-product-data>
      </div>
    </div>
  </div>
</template>

<script>
import layoutProductData from '@/components/layout/product/layout-product-data'
export default {
  components: {
    layoutProductData
  },
  props: {
    value: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    open() {
      window.location = '/product'
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-product-card-preview {
  position: fixed;
  z-index: 502;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0em 15px;
  .layout-product-data {
    padding-bottom: 0;
  }
}
.overlay {
  background: rgba(0, 0, 0, 0.1);
}
.window {
  background: #fff;
  position: relative;
  z-index: 1;
  max-height: 90%;
  overflow: scroll;
  padding: 1.5em;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}
@media screen and (max-width: 767px) {
  .window {
    padding: 1.3em 0;
    max-height: 80vh;
  }
}
</style>
